import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import _isFunction from 'lodash/isFunction'

export default ({ metaType, metaTitle, metaDescription, metaImages = [], metaLinks = [] }) => WrappedComponent =>
  class Meta extends Component {
    static displayName = `Meta(${WrappedComponent.displayName || WrappedComponent.name || 'WrappedComponent'})`

    render() {
      const type = _isFunction(metaType) ? metaType(this.props) : metaType
      const title = _isFunction(metaTitle) ? metaTitle(this.props) : metaTitle
      const description = _isFunction(metaDescription) ? metaDescription(this.props) : metaDescription
      const metaImagesResolved = _isFunction(metaImages) ? metaImages(this.props) : metaImages
      const links = _isFunction(metaLinks) ? metaLinks(this.props) : metaLinks

      const metaImageElements = metaImagesResolved.reduce((acc, { url, width, height }) => {
        acc.push({ type: '', value: url })
        width && acc.push({ type: ':width', value: width })
        height && acc.push({ type: ':height', value: height })
        return acc
      }, [])

      return (
        <Fragment>
          <Helmet>
            {type && <meta property="og:type" content={type} />}

            {title && <title>{`${title} - NRK Radio`}</title>}
            {title && <meta property="og:title" content={`${title} - NRK Radio`} />}

            {description && <meta name="description" content={description} />}
            {description && <meta property="og:description" content={description} />}

            {metaImageElements.map(({ type, value }) => (
              <meta key={`og:image${type}:${value}`} property={`og:image${type}`} content={value} />
            ))}

            {links.map(linkAttrs => (
              <link key={`${linkAttrs.rel}:${linkAttrs.href}`} {...linkAttrs} />
            ))}
          </Helmet>
          <WrappedComponent {...this.props} />
        </Fragment>
      )
    }
  }

import React from 'react'
import { Link } from 'react-router-dom'
import Box from '../../RadioUI/Box'
import Text from '../../RadioUI/Text'
import ContentWidth from '../../RadioUI/ContentWidth'
import Button from '../../RadioUI/Button'
import FourOhFourAnimation from './404Animation'
import { useColors } from '../../RadioUI/theme/Color'

const FourOhFour = () => {
  const colors = useColors()

  return (
    <Box textAlign="center" minH="calc(100vh - 350px)">
      <Box bg={colors.main.dark} h={{ base: '90px', md: '285px' }}>
        <FourOhFourAnimation />
      </Box>
      <ContentWidth pb={12}>
        <Text as="h2" fontSize="28px" pt={12} weight="bold">
          Beklager
        </Text>
        <Text pb={6}>Siden du leter etter finnes ikke, eller nettadressen er feil.</Text>
        <Button type={null} as={Link} lineHeight="2.5rem" to="/" data-test="returnToFrontpage">
          Forsiden
        </Button>
      </ContentWidth>
    </Box>
  )
}

FourOhFour.propTypes = {}

export default FourOhFour
